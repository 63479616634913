.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.privacy-policy-section {
  display: flex;
  justify-content: center;
}

.privacy-policy-section h3 {
  font-size: 34px;
  margin-top: 16px;
}

.privacy_policybox {
  max-width: 1220px;
}



.privacy-policy-content p,
.privacy-policy-content ul {

  margin-bottom: 16px;
}



.user-list-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);

  gap: 20px;
  padding: 20px;
}


/* User box styles with hover animation */
.user-box {

  justify-content: space-between;
  align-items: center;
  background-color: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
  /* Ensure smooth hover effect */
}

/* Hover effect to elevate and highlight the box */
.user-box:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}

/* Left side: Mute/Unmute status */
.mute-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Mute/Unmute color transitions */
.mute-status.bg-red {
  background-color: #e63946;
  color: white;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.mute-status.bg-success {
  background-color: #2a9d8f;
  color: white;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Big icons for muted/unmuted status with smooth transitions */
.icon-muted-large,
.icon-unmuted-large {
  font-size: 40px;
  margin-bottom: 10px;
  transition: transform 0.3s ease;
}

/* Icon animation: slight bounce effect on hover */
.mute-status:hover .icon-muted-large,
.mute-status:hover .icon-unmuted-large {
  transform: scale(1.2);
}

/* Right side: User first name */
.userboxcontent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}

/* Text animations for smooth transitions */
.user-field {
  font-size: 18px;
  color: #333;
  margin: 14px 0px;
  font-weight: bold;
  transition: color 0.3s ease, transform 0.3s ease;
}

/* Hover effect on user-field to slightly shift the text */
.user-field:hover {
  color: #000;
  transform: translateX(5px);
}