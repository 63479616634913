.grid-header{
    background-color: black;
    color: beige;
}
@media only screen and (max-width: 600px) {
    .modal-class {
      width: 70%;
    }
}
@media only screen and (min-width: 600px) {
    .modal-class {
      width: 50%;
    }
}
.status-bg-green {
  background-color: green;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  color: white;
  border-radius: 4px;
}
.status-bg-red {
  background-color: red;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
  color: white;
  border-radius: 4px;
}